import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Routes, Route, Navigate } from "react-router-dom"
import FilesList from "./views/FilesList"
import ImageViewer from "./views/ImageViewer"
import VideoViewer from "./views/VideoViewer"
import AuthenticationButton from "./auth/AuthenticationButton"
import Loading from "./components/Loading"
import ProtectedRoute from "./auth/ProtectedRoute"

export default function Router(): React.ReactElement {
	return (
		<Routes>
			<Route
				path="/browse"
				element={<ProtectedRoute component={FilesList} />}
			/>
			<Route
				path="/view/image"
				element={<ProtectedRoute component={ImageViewer} />}
			/>
			<Route
				path="/view/video"
				element={<ProtectedRoute component={VideoViewer} />}
			/>
			<Route path="/" element={<Home />} />
		</Routes>
	)
}

function Home() {
	const { isAuthenticated, isLoading } = useAuth0()
	return isLoading ? (
		<Loading />
	) : isAuthenticated ? (
		<Navigate to="/browse" />
	) : (
		<div className="flex flex-col justify-center items-center h-full">
			<span className="dark:text-white w-auto text-center text-3xl pb-5">
				You must login to access the site
			</span>
			<AuthenticationButton />
		</div>
	)
}
