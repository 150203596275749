import React from "react"
import { useAuth0 } from "@auth0/auth0-react"

const LoginButton = (): React.ReactElement => {
	const { loginWithRedirect } = useAuth0()

	return (
		<button
			className="bg-blue-600 border-blue-500 dark:bg-blue-700 text-white font-medium dark:border-blue-900 rounded border shadow hover:shadow-md px-4 py-2"
			onClick={() => loginWithRedirect()}
		>
			Login with Auth0
		</button>
	)
}

export default LoginButton
