import { mdiArrowLeft, mdiHome, mdiRefresh } from "@mdi/js"
import Icon from "@mdi/react"
import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

export default function NavBar(props: any): React.ReactElement {
	const query = useQuery()
	const navigate = useNavigate()
	const [path, setPath] = useState("")

	useEffect(() => {
		const path = query.get("path")
		if (path) {
			setPath(path)
		}
	}, [query])

	function goBack() {
		const newPath = path.split("/").slice(0, -2).join("/")
		navigate(`/browse?path=${newPath}/`)
	}

	return (
		<div className="sticky z-10 top-0 bg-blue-500 dark:bg-blue-800 shadow p-4 flex items-center text-white">
			<span className="cursor-pointer pr-4" onClick={() => goBack()}>
				{path === "/" ? (
					<Icon
						path={mdiHome}
						size={1.25}
						horizontal
						vertical
						color="white"
						rotate={180}
					/>
				) : (
					<Icon
						path={mdiArrowLeft}
						size={1.25}
						horizontal
						vertical
						color="white"
						rotate={180}
					/>
				)}
			</span>
			<span className="font-medium">{path === "/" ? "Home" : path}</span>
			<span
				className="cursor-pointer pr-4 absolute right-0"
				onClick={() => props.forceRefresh()}
			>
				<Icon
					path={mdiRefresh}
					size={1.25}
					horizontal
					vertical
					color="white"
					rotate={180}
				/>
			</span>
		</div>
	)
}
