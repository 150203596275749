import React, { useState } from "react"
import { fetchThumbnail } from "../lib/api"
import Icon from "@mdi/react"
import {
	mdiFileImageOutline,
	mdiFileOutline,
	mdiFileVideoOutline,
	mdiFolder
} from "@mdi/js"
import { useMediaPredicate } from "react-media-hook"

export default function FileItem(props: any): React.ReactElement {
	const [thumbnail, setThumbnail] = useState("")
	const youtubeMode = false
	const preferredTheme = useMediaPredicate("(prefers-color-scheme: dark)")
		? "dark"
		: "light"

	function iconName(mime: string): string {
		let name = mdiFolder
		const type = mime.split("/")[0]

		switch (type) {
			case "folder":
				break
			case "image":
				name = mdiFileImageOutline
				getThumbnail()
				break
			case "video":
				name = mdiFileVideoOutline
				getThumbnail()
				break
			default:
				name = mdiFileOutline
				break
		}

		return name
	}

	function getThumbnail() {
		// eslint-disable-next-line react/prop-types
		const path = props?.item?.path
		if (path) {
			// eslint-disable-next-line react/prop-types
			fetchThumbnail(path).then((image) => {
				setThumbnail(image)
			})
		}
	}

	return (
		<div
			className="card shadow hover:shadow-md rounded w-full cursor-pointer"
			onClick={() => props.onClick(props.item)}
			onAuxClick={() => props.link && window.open(props.link, "_blank")}
		>
			{thumbnail ? (
				<div className="aspect-w-16 aspect-h-9 rounded-t">
					<img className="rounded-t" src={thumbnail} />
				</div>
			) : (
				<div className="bg-gray-200 dark:bg-gray-800 rounded-t aspect-w-16 aspect-h-9">
					<div className="flex items-center justify-center">
						<Icon
							path={iconName(props.item.mime)}
							title={props.type}
							size={2}
							horizontal
							vertical
							color={
								preferredTheme === "dark" ? "white" : "black"
							}
							rotate={180}
						/>
					</div>
				</div>
			)}
			<div className="item-title-container flex flex-col bg-white dark:bg-gray-900 rounded-b p-2">
				<span className="text-gray-900 dark:text-white font-semibold break-all">
					{props.item.name}
				</span>
				<span className="text-gray-600 dark:text-gray-400">
					{props.item.mime}
				</span>
			</div>
		</div>
	)
}
