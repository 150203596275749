import { mdiClose } from "@mdi/js"
import Icon from "@mdi/react"
import React, { useEffect, useRef, useState } from "react"
import { useMediaPredicate } from "react-media-hook"
import { useNavigate, useLocation } from "react-router-dom"
import { fetchFile } from "../lib/api"

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

export default function ImageViewer(): React.ReactElement {
	const [image, setImage] = useState("")
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [loaded, setLoaded] = useState(false)

	const imageDom = useRef() as React.MutableRefObject<HTMLImageElement>

	const query = useQuery()
	const navigate = useNavigate()
	const preferredTheme = useMediaPredicate("(prefers-color-scheme: dark)")
		? "dark"
		: "light"

	useEffect(() => {
		const path = query.get("path")
		if (path) {
			loadImage(path)
		} else {
			navigate("/browse?path=/")
		}
	}, [])

	async function loadImage(path: string) {
		const { url } = await fetchFile(path)
		const image = imageDom.current

		if (image) {
			image.addEventListener("load", function () {
				setWidth(this.naturalWidth)
				setHeight(this.naturalHeight)
				setImage(url)
				setLoaded(true)
			})
			image.src = url
		}
	}

	function quitPage() {
		const path = query.get("path")?.split("/").slice(0, -1)
		if (history.length > 2) {
			navigate(-1)
		} else {
			navigate(`/browse?path=${path?.join("/")}`)
		}
	}

	return (
		<div className="dark:text-white fixed flex inset-0 items-center justify-center">
			<span
				className="fixed top-4 right-4 cursor-pointer"
				onClick={() => quitPage()}
			>
				<Icon
					path={mdiClose}
					size={2}
					horizontal
					vertical
					color={preferredTheme === "dark" ? "white" : "black"}
					rotate={180}
				/>
			</span>
			<img
				ref={imageDom}
				style={{ maxHeight: height, maxWidth: width }}
				className="h-full w-full object-contain"
			/>
			{loaded ? null : <span>Loading...</span>}
		</div>
	)
}
