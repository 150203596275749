import React from "react"
import * as ReactDOMClient from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory"

const container = document.getElementById("root")

if (container) {
	// Create a root.
	const root = ReactDOMClient.createRoot(container)

	root.render(
		<BrowserRouter>
			<Auth0ProviderWithHistory>
				<React.StrictMode>
					<App />
				</React.StrictMode>
			</Auth0ProviderWithHistory>
		</BrowserRouter>
	)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
