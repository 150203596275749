import React, { useEffect, useState } from "react"
import { fetchFolderContent } from "../lib/api"
import { useNavigate, useSearchParams } from "react-router-dom"
import mime from "mime"
import FileItem from "../components/FileItem"
import useWindowSize from "../hooks/useWindowSize"
import NavBar from "../components/NavBar"

interface FileListItem {
	name: string
	path: string
	mime: string
	type: string
	key: string
}

export default function FilesList(_props: any): React.ReactElement {
	const [searchParams] = useSearchParams()
	const [currentPath, setCurrentPath] = useState("")
	const [filePath, setPath] = useState("")
	const [content, setContent] = useState([] as FileListItem[])
	const [mode] = useState("row")
	const size = useWindowSize()
	const navigate = useNavigate()

	useEffect(() => {
		const path = searchParams.get("path")
		if (path !== currentPath) {
			setCurrentPath(path ?? "")
			console.log({ path, filePath, currentPath })
			if (path && path !== filePath) {
				updateFileList(path)
			} else {
				navigate("/browse?path=/")
			}
		}
	}, [searchParams])

	function gridDimensions() {
		const minItemWidth = 316
		let columns = Math.floor(size.width / minItemWidth) || 1
		if (columns > 12) {
			columns = 12
		}

		return {
			gridContainer: `grid grid-flow-row grid-cols-${columns} gap-4 justify-between justify-items-stretch w-full`
		}
	}

	function makeUrl(item: FileListItem) {
		switch (item.type) {
			case "folder":
				return `/browse?path=${item.path}`
			case "image":
				return `/view/image?path=${item.path}`
			case "video":
				return `/view/video?path=${item.path}`
		}
	}

	function onPress(item: FileListItem) {
		switch (item.type) {
			case "folder":
				navigate(`/browse?path=${item.path}`)
				break
			case "image":
				navigate(`/view/image?path=${item.path}`)
				break
			case "video":
				navigate(`/view/video?path=${item.path}`)
				break
		}
	}

	async function updateFileList(
		newPath?: string,
		forceRefresh = false
	): Promise<any> {
		if (newPath && newPath !== filePath) {
			setPath(newPath)
		}
		const data = await fetchFolderContent(newPath || filePath, forceRefresh)
		const folders = data.CommonPrefixes.map((folder: any) => {
			return {
				name: folder.Prefix.slice(0, -1).split("/").slice(-1)[0],
				path: folder.Prefix,
				mime: "folder",
				type: "folder",
				key: folder.Prefix
			}
		})
		const files = data.Contents.sort(function (a: any, b: any) {
			return (
				new Date(b.LastModified).getTime() -
				new Date(a.LastModified).getTime()
			)
		}).map((file: any) => {
			const extensionReg = new RegExp(/(?:\.([^.]+))?$/)

			const ext = (extensionReg.exec(file.Key) as string[])[1]

			const mimeType: string = ext ? mime.getType(ext) ?? "" : ""
			return {
				name: file.Key.split("/").slice(-1)[0],
				path: file.Key,
				mime: mimeType,
				type: mimeType.split("/")[0],
				key: file.Key
			}
		})

		setContent([...folders, ...files])
	}

	return (
		<div>
			<NavBar forceRefresh={() => updateFileList(undefined, true)} />
			<div className="flex p-4 w-full justify-between">
				{mode === "row" ? (
					<div className={gridDimensions().gridContainer}>
						{content.map((item) => (
							<FileItem
								onClick={(i: FileListItem) => onPress(i)}
								link={makeUrl(item)}
								item={item}
								key={item.key}
							/>
						))}
					</div>
				) : (
					<div className="grid grid-flow-row gap-4 justify-between">
						{content.map((item) => (
							<FileItem
								onClick={(i: FileListItem) => onPress(i)}
								item={item}
								key={item.key}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	)
}
