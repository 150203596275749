import React from "react"
import Router from "./Router"

function App(): React.ReactElement {
	return (
		<div className="App bg-gray-100 dark:bg-black w-full h-full inset-0 fixed overflow-y-auto">
			<Router />
		</div>
	)
}

export default App
