import React from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from "../components/Loading"

// eslint-disable-next-line react/prop-types
export const ProtectedRoute: React.FC<any> = ({ component }) => {
	const Component = withAuthenticationRequired(component, {
		onRedirecting: () => <Loading />
	})

	return <Component />
}
export default ProtectedRoute
