import React from "react"

export default function Loading(): React.ReactElement {
	return (
		<div className="flex flex-col justify-center items-center h-full">
			<span className="dark:text-white w-auto text-center text-3xl pb-5">
				Loading...
			</span>
		</div>
	)
}
