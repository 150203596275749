import axios from "axios"

const API_URL = "https://api.of-explorer.alex73630.xyz"

async function fetchFolderContent(path: string, forceRefresh?: boolean) {
	try {
		if (path === "/") {
			path = ""
		}
		const { data } = await axios.get(
			`${API_URL}/bucket/browse/${path}${
				forceRefresh ? "?forceRefresh=true" : ""
			}`
		)

		return data
	} catch (error) {
		console.error(error)
	}
}

async function fetchFile(path: string) {
	try {
		const { data } = await axios.get(`${API_URL}/bucket/read/${path}`)

		return data
	} catch (error) {
		console.error(error)
	}
}

async function fetchImageAsBase64(url: string): Promise<any> {
	try {
		return axios
			.get(url, {
				responseType: "arraybuffer"
			})
			.then((response) => {
				const image = btoa(
					new Uint8Array(response.data).reduce(
						(data, byte) => data + String.fromCharCode(byte),
						""
					)
				)
				return `data:${response.headers[
					"content-type"
				].toLowerCase()};base64,${image}`
			})
	} catch (error) {
		console.error(error)
	}
}

async function fetchThumbnail(path: string): Promise<any> {
	try {
		if (path.includes("mp4")) {
			path += ".jpg"
		}

		return `${API_URL}/public/thumbnails/${path}`
	} catch (error) {
		console.error(error)
	}
}

export { fetchFolderContent, fetchFile, fetchImageAsBase64, fetchThumbnail }
